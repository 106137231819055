











































import {Component, Prop, Watch} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import Swiper from 'swiper';
import moment from 'moment';
import {reservationModule} from '@/store';
import DataFormat from '@/shared/utils/dataFormat';
import {EventBus} from '@/config/config';
import {find, findIndex} from 'lodash';
import * as firebase from 'firebase/app';

@Component({
    components: {
        'wait-reservation-info': () => import('@/components/common/WaitReservationInfo.vue'),
    }
})
export default class NearSchedule extends mixins(DataFormat) {
    @Prop
    ({required: true})
    public place!: any;
    @Prop()
    public placeId!: any;
    @Prop()
    public selectLang!: any;
    @Prop()
    public reserveParams!: {
        date: any;
        peopleCount: number;
        time: string;
        listTime: any;
    };

    public setTimeLoading: boolean = false;
    public initReserveParams: any = null;
    public waitReservationInfo: boolean = false;
    public swiper: any = null;

    public beforeDestroy() {
        this.setTimeLoading = false;
    }

    public eventClick() {
        const eventName = 'click';
        const eventParams: any = {
            button_type: 'reserve',
            type: 'place',
            id: this.place.id.toString(),
            name: this.place.name
        }
        firebase.analytics().logEvent(eventName, eventParams);
    }

    public eventClickTimeblock(time) {
        const eventName = 'click_timeblock';
        const eventParams: any = {
            time,
            type: 'place',
            id: this.place.id.toString(),
            name: this.place.name
        }
        firebase.analytics().logEvent(eventName, eventParams);
    }

    public mounted() {
        const params: any = {
            place_id: this.place.id,
            people_count: this.reserveParams.peopleCount,
            date: this.reserveParams.date
        };
        if (this.placeId.toString() === this.place.id.toString()) {
            reservationModule.fetchNearSchedule(params).then((res: any) => {
                if (res.status) {
                    this.reserveParams.date = this.nearSchedule.date;
                    this.reserveParams.listTime = this.nearSchedule.times;
                    this.reserveParams.peopleCount = this.nearSchedule.people_count;

                    this.setTimeLoading = true;

                    this.initReserveParams = {};
                    this.initReserveParams.peopleCount = this.reserveParams.peopleCount;
                    this.initReserveParams.date = this.reserveParams.date;
                    this.initReserveParams.listTime = this.reserveParams.listTime;
                    this.$nextTick(() => {
                        this.activeSwiper();
                    });
                }
            });
        }
        EventBus.$on('updateInitNearSchedule', () => {
            this.reserveParams.date = this.initReserveParams.date;
            this.reserveParams.peopleCount = this.initReserveParams.peopleCount;
            this.reserveParams.listTime = this.initReserveParams.listTime;
        })
    }

    //캘린더에서 선택 한 시간대 반영 시 갱신
    @Watch('reserveParams.listTime')
    public fetchNearParams() {
        this.activeSwiper();
    }

    get nearSchedule() {
        return reservationModule.nearSchedule;
    }

    public clickTime(event: any, time: string) {
        if (!event.target.parentNode.classList.contains('disabled')) {
            this.reserveParams.time = time;
            this.reserveGo();
        }
        this.eventClickTimeblock(time);
    }

    public reserveGo() {
        this.eventClick();
        if (
            this.place.reservation_method === 'normal' ||
            (
                this.place.reservation_method === 'os_normal' &&
                this.place.use_realtime_reserve !== 1
            )
        ) {
            this.waitReservationInfo = true;
            return;
        }
        //예약하기 모달 열기
        EventBus.$emit('updateReserveModalState', false);
        EventBus.$emit('setPeopleDetail');
        this.$modal.show('reserve');
    }
    public waitReservation() {
        EventBus.$emit('updateReserveModalState', false);
        EventBus.$emit('setPeopleDetail');
        this.$modal.show('reserve');
        this.waitReservationInfo = false;
    }

    public activeSwiper(time: string = '') {
        if (this.reserveParams.listTime === null) return;
        // if (!this.setTimeLoading) return;

        if (this.swiper) {
            this.swiper = null;
        }

        let index = 0;
        if (this.reserveParams.listTime) {
            if (time !== '') { //시간이 잇으면 해당 시간으로
                index = this.reserveParams.listTime.findIndex(item => item.time === time);
            } else { //없으면 가장 빠른 활성화 시간대로
                index = this.reserveParams.listTime.findIndex(item => item.is_active);
            }
        }

        this.swiper = new Swiper('.near-schedule-container .timeline-list-slide', {
            slidesPerView: 'auto',
            initialSlide: index,
            freeMode: true,
            observer: true,
            observeParents: true,
        });
    }

    @Watch('waitReservationInfo')
    public waitReservationInfoUpdate() {
    if (this.waitReservationInfo === false) {
        document.querySelector('html' as any).classList.remove('open-modal');
    } else {
        document.querySelector('html' as any).classList.add('open-modal');
    }
    }
}
